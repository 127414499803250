import axios from "axios";
import { AuthActions } from "../reducers/AuthReducer";
import store from "../store";
import client from "./client";

const API_URL = process.env.REACT_APP_API_URL;

export const login = async (username: string, password: string) => {
  try {
  const {
    data: { token },
  } = await axios.post<{ token: string}>(
    `${API_URL}auth/login`,
    {
      username,
      password,
    }
  );
 
  store.dispatch({ type: AuthActions.SetSession, payload: { token } });
  localStorage.setItem("token", token);
  }
  catch (error) {
    alert((error as any).response.data)
  }
};

export const initLogin = async () => {
  const token = localStorage.getItem("token");

  if (token) {
    try {
      client.get(`${API_URL}auth/validate`, {
        headers: {
          "x-auth-token": token,
        },
      });
      store.dispatch({ type: AuthActions.SetSession, payload: { token } });
    } catch (error) {
      localStorage.removeItem("token");
      store.dispatch({ type: AuthActions.DelSession, payload: null });
    }
  }
};
